import { QuestionCircleOutlined, UndoOutlined } from "@ant-design/icons"
import LogoNotaire from "Assets/logoNotaire.png"

export default function ClientHeader() {
    return (
        <div style={{ height: 40, borderBottom: "1px solid #DADADA" }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", height: 40 }}>
                <UndoOutlined onClick={() => window.location.reload()} style={{ transform: "rotate(75deg)", color: "#828282", fontSize: 20 }} />
                <a target={"_blank"} href="https://tawk.to/chat/6149db23d326717cb6829699/1fg4801an">
                    <QuestionCircleOutlined style={{ margin: "0px 15px 0px 15px", color: "#828282", fontSize: 20, marginTop: 4 }} />
                </a>
            </div>
            <img src={LogoNotaire} style={{
                width: 50,
                height: 50,
                border: "0.5px solid #DADADA",
                borderRadius: "50%",
                position: "absolute",
                top: 20,
                left: "50%",
                transform: "translate(-50%, 0)"
            }} />
        </div>
    )
}