import { db } from "firebaseConfig";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";



export const setLinkOpen = async (linkID: string): Promise<void> => {
    try {
        await setDoc(doc(db, "ClientLinkOpen", linkID), {
            date: serverTimestamp()
        }, { merge: true });
        return
    } catch (err) {
        throw err;
    }
};

export const updateLinkInfo = async (linkID: string): Promise<void> => {
    try {
        await setDoc(doc(db, "ClientLink", linkID), { isSet: true }, { merge: true });
        return
    } catch (err) {
        throw err
    }
}

export const setClientRating = async (linkID: string, rating: number) => {
    try {
        await setDoc(doc(db, "Ratings", linkID), {
            rating,
            linkID
        }, { merge: true });
    } catch (err) {
        throw err
    }
}



