import { Button, Result } from 'antd';
import { FC } from 'react';

export type UploaderErrorsType = "already-set" | "dont-link" | "expired" | "other"

const UploaderErrors: FC<{ type: UploaderErrorsType }> = (props) => {

  const getText = (): { title: string, descr: string } => {
    let title = "Une Erreur est survenue"
    let descr = "Une erreur est survenue, veuillez recharger la page ou réessayer plus tard."
    switch (props.type) {
      case "already-set":
        title = "Ce lien a déjà été complété."
        descr = "Il semblerait que vous ayez déjà envoyé vos fichiers. Si ce n'est pas le cas, veuillez contacter l'expéditeur du lien."
        break;
      case "dont-link":
        title = "Ce lien n'existe pas."
        descr = "Etes-vous sur d'avoir entré le bon lien ?"
        break;
      case "expired":
        title = "Ce lien est expiré."
        descr = "Vous ne pouvez envoyer vos fichiers que pour un maximum de 16 jours après l'envoi du lien."
        break;
      case "other":
      default:
        break;
    }
    return { title, descr }
  }

  const onClose = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Result
        title={getText().title}
        subTitle={getText().descr}
        extra={[
          <Button type="primary" size={"large"} onClick={onClose}>
            Fermer la fenêtre
          </Button>
        ]}
      />
    </div>
  )
}

export default UploaderErrors;