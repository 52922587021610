import { CameraOutlined, FilePdfOutlined } from '@ant-design/icons';
import { FC, useContext, useRef, useState } from "react";
import { analystic_logEvent } from "utils/analystic";
import { Modal, Upload, Button } from 'antd';
import styles from "./index.module.css"
import ClientHeader from 'Components/UI/Uploader/Header';
import { UploaderContext } from 'Providers/UploaderProvider';
import { UploadChangeParam } from 'antd/lib/upload';
import ShowErrorNotification from 'Components/UI/Notif';

interface ClientFilePickProps {
  back: () => void
  next: () => void
}

type fileType = "file" | "image"

const acceptedFilesType = ["image/png", "image/tiff", "image/jpeg", "image/webp", "application/pdf"]

const ClientFilePick: FC<ClientFilePickProps> = (props) => {
  const fileRef = useRef(null);
  const photoRef = useRef(null);

  const { uploader, setUploader } = useContext(UploaderContext)

  const [clickCounter, setClickCounter] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)

  const handleFilePick = (type: fileType, file: File) => {
    analystic_logEvent(`client_onboarding_${type}_picked`, { linkID: uploader?.linkID })
    setUploader!({ ...uploader!, file: file! })
    props.next()
  }

  const handleBtnClicked = () => {
    setClickCounter((prev) => prev + 1)
    if (clickCounter > 3) { setModalOpen(true) }
  }

  const fileProps = {
    onChange(info: UploadChangeParam) {
      if (!acceptedFilesType.includes(info.file.type!)) {
        ShowErrorNotification("Votre fichier n'est pas dans un format valide. Merci de sélectionner un fichier Pdf ou une image.")
        return
      }
      if (info.file.percent == 0) {
        handleFilePick("file", info.file.originFileObj!)
      }
    },
  };

  const imageProps = {
    onChange(info: UploadChangeParam) {
      if (info.file.percent == 0) {
        handleFilePick("image", info.file.originFileObj!)
      }
    },
  };

  return (
    <>
      <ClientHeader />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ padding: "8px 20px 20px", marginTop: 40, maxWidth: 500 }}>
          <h1
            style={{
              fontSize: 17,
              fontWeight: "400",
              color: "#000",
              textAlign: "center"
            }}
          >
            Quel format souhaitez vous utiliser ?
          </h1>
          <p
            style={{
              marginTop: 5,
              fontSize: 17,
              color: "#525c66",
              textAlign: "center"
            }}
          >
            Sélectionner le format de transfert pour votre R.I.B.
          </p>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 40 }}>
            <Upload maxCount={1} {...imageProps} accept="image/png, image/tiff, image/jpeg, image/webp;capture=camera">
              <Button onClick={handleBtnClicked} style={{ marginTop: 20, width: 240 }} className={styles.inputButton} icon={<CameraOutlined style={{ marginTop: 5 }} />}>Prendre une photo</Button>
            </Upload>

            <Upload maxCount={1} {...fileProps} accept="application/pdf, image/png, image/tiff, image/jpeg, image/webp" >
              <Button onClick={handleBtnClicked} style={{ marginTop: 30, width: 240 }} className={styles.inputButton} icon={<FilePdfOutlined style={{ marginTop: 5 }} />}>Envoyer une pièce jointe</Button>
            </Upload>
          </div>
        </div>
      </div>

      <Modal visible={modalOpen} footer={null}  >
        <h2>Il semble que votre navigateur rencontre un problème</h2>
        <div style={{ marginTop: 30 }}>
          <span style={{ fontSize: 15, fontWeight: "500" }}>Cliquez ici si vous souhaitez envoyer une image: </span>
          <input type="file" accept="image/png, image/tiff, image/jpeg, image/webp;capture=camera" id="image" style={{ marginTop: 10 }} ref={photoRef} onChange={(e) => handleFilePick("image", e.target.files![0])} />
        </div>

        <div>
          <span style={{ fontSize: 15, fontWeight: "500" }}>Cliquez ici si vous souhaitez envoyer un fichier: </span>
          <input type="file" id="file" accept="application/pdf" ref={fileRef} style={{ marginTop: 10 }} onChange={(e) => handleFilePick("file", e.target.files![0])} />
        </div>
      </Modal>
    </>
  )
}

export default ClientFilePick;


