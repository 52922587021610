import ClientHeader from 'Components/UI/Uploader/Header';
import ClientNextButton from 'Components/UI/Uploader/NextButton';
import { UploaderContext } from 'Providers/UploaderProvider';
import { FC, useContext } from "react";


const HelloClientPage: FC<{ next: () => void }> = (props) => {

  const { uploader } = useContext(UploaderContext)
  const { username } = uploader!

  return (
    <>
      <ClientHeader />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ padding: "8px 20px 20px", marginTop: 40, maxWidth: 500 }}>
          <h1
            style={{
              fontSize: 17,
              fontWeight: "400",
              textAlign: "left",
              color: "#000",
            }}
          >
            <span style={{ textDecoration: "underline" }}>Étude {username}</span> vous a envoyé une demande de transfert de R.I.B.
          </h1>
          <p
            style={{
              fontSize: 17,
              color: "#525c66",
              marginTop: 30,
              fontWeight: "700",
            }}
          >
            Ne partagez pas ce lien.
          </p>
          <p
            style={{
              marginTop: 5,
              fontSize: 17,
              color: "#525c66",
            }}
          >
            Ce lien sécurisé vers Safety check vous est dédié. Ne partagez pas ce lien, les informations transférées via ce lien vous seront propres.
          </p>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
            <ClientNextButton isActive={true} onpress={props.next} title={"Suivant"} />
          </div>
        </div>
      </div>
    </>

  )
}

export default HelloClientPage
