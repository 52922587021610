import { Button } from "antd";
import { FC } from "react";

interface ClientNextBtnProps {
    title: string
    onpress: () => void
    isActive: boolean
}

const ClientNextButton: FC<ClientNextBtnProps> = (props) => {
    return (
        <Button
            disabled={!props.isActive}
            style={{
                padding: "8px 16px", height: 50, width: 280, borderRadius: 7,
                background: props.isActive ? "#0666EB" : "#EEEFF0",
                color: props.isActive ? "white" : "#828B94",
                fontWeight: "700",
            }}
            onClick={props.onpress}>
            {props.title}
        </Button>
    )
}

export default ClientNextButton;