import { FC, useEffect, useState } from "react";
import ClientPhoneCode from "../PhoneCode";
import ThanksClientPage from "../ThanksPage";
import HelloClientPage from "../Hello";
import Spinner from "Components/UI/Spinner";
import { logout } from "utils/auth";
import ClientFilePick from "../FilePick";
import { setLinkOpen } from "utils/database";
import ShowErrorNotification from "Components/UI/Notif";
import ClientSignPage from "../SignPage";
import { useParams } from "react-router-dom";
import { UploaderProvider } from "Providers/UploaderProvider";
import { Uploader } from "types/Uploader.type";
import { getClientLink } from "utils/server";
import UploaderErrors, { UploaderErrorsType } from "Screens/Errors/UploaderErrors";


export default function UploaderController() {
  const params = useParams()

  const [uploader, setUploader] = useState<Uploader | null>(null)
  const [error, setError] = useState<UploaderErrorsType | undefined>(undefined)

  useEffect(() => {
    if (uploader) { return }

    //Signout to avoid overlaping account
    logout()

    //get link params
    const { linkID } = params;

    getLinkInfo(linkID!).then(() => {
      setOpen(linkID!).catch(() => { })
    }).catch((err) => {
      console.log("err: ", err)
      try {
        const message = err.message as UploaderErrorsType
        setError(message)
      } catch {
        setError("other")
      }
    })

    /*fetchRetry(linkID!, 4).catch(() => {
       navigation("/notfound")
     })*/
  }, []);

  const getLinkInfo = async (linkID: string) => {
    try {
      const result = await getClientLink(linkID)
      const { phoneNumber, username, userID } = result
      setUploader(prev => ({
        ...prev,
        linkID,
        phoneNumber,
        username,
        userID
      }))
      return
    } catch (err) {
      throw err;
    }
  }

  const fetchRetry = async (linkID: string, n: number): Promise<void> => {
    try {
      return await getLinkInfo(linkID)
    } catch (err) {
      if (n === 1) throw err;
      return await fetchRetry(linkID, n - 1);
    }
  };

  const setOpen = async (linkID: string) => {
    try {
      await setLinkOpen(linkID)
    } catch (err) { return }
  }

  const onError = (message: string) => {
    ShowErrorNotification(message)
  }

  if (error) return <UploaderErrors type={error} />

  return (
    <div>
      {!uploader ? (<div style={{ height: "100vh" }}> <LoadingScreen /> </div>) : (<ClientFlowController uploader={uploader} onError={onError} />)}
    </div>
  );
}

const ClientFlowController: FC<{ uploader: Uploader, onError: (message: string) => void }> = (props) => {

  const [index, setIndex] = useState(0)

  const next = () => { setIndex(index + 1) }
  const back = () => { setIndex(index - 1) }

  return (
    <UploaderProvider uploader={props.uploader}>
      <div>
        {index == 0 && <HelloClientPage next={next} />}
        {index == 1 && <ClientFilePick next={next} back={back} />}
        {index == 2 && <ClientSignPage next={next} back={back} />}
        {index == 3 && <ClientPhoneCode next={next} back={back} onError={props.onError} />}
        {index == 4 && <ThanksClientPage linkID={props.uploader.linkID} />}
      </div>
    </UploaderProvider>
  );
}

function LoadingScreen() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Spinner />
      <h1 style={{ fontSize: 16, marginTop: 25, textAlign: "center" }}>Un instant, la certification peut prendre quelques secondes...</h1>
    </div>
  );
}

