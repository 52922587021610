import { useState, useContext, FC } from "react";
import { Progress } from 'antd';
import { updateLinkInfo } from "utils/database";
import PhoneCodeVerification from "Components/PhoneCode";
import { analystic_logEvent } from "utils/analystic";
import { ref, uploadBytesResumable, uploadString } from "firebase/storage";
import { storage } from "firebaseConfig";
import ClientHeader from "Components/UI/Uploader/Header";
import { uploadSignature } from "utils/storage";
import { UploaderContext } from 'Providers/UploaderProvider';

interface ClientPhoneCodeProps {
  next: () => void
  back: () => void
  onError: (message: string) => void
}

const ClientPhoneCode: FC<ClientPhoneCodeProps> = (props) => {

  const { uploader } = useContext(UploaderContext)
  const [progress, setProgress] = useState(0)

  const onSuccess = async (userID: string) => {
    const storagePath = `clientsFiles/${uploader!.userID}/${uploader!.linkID}/${userID}/clientFile`

    const userStorageRef = ref(storage, storagePath);
    const uploadTask = uploadBytesResumable(userStorageRef, uploader!.file!, { contentType: uploader!.file!.type });

    uploadTask.on('state_changed', (snapshot) => {
      const loadingProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(loadingProgress);
    }, (error) => {
      // Handle unsuccessful uploads
      analystic_logEvent("client_onboarding_file_uploaded_error", { linkID: uploader?.linkID })
      uploadTask.cancel();
      props.onError("Une erreur s'est produite, veuillez réessayer en rechargeant la page.")
      setProgress(0)
    }, () => {
      //Success
      analystic_logEvent("client_onboarding_file_uploaded_success", { linkID: uploader?.linkID })
      updateLinkInfo(uploader!.linkID).then(() => {
        uploadSignature(uploader!, userID).finally(() => {
          props.next()
        })
      }).catch((err) => {
        setProgress(0)
        //TODO: DELETE FILE
        props.onError("Une erreur s'est produite, veuillez réessayer en rechargeant la page.")
      })
    });
  }

  if (progress !== 0) {
    return (<LoadingScreen progress={progress} />)
  }

  return (
    <PhoneCodeVerification phoneNumber={uploader!.phoneNumber} onSuccess={onSuccess} onError={props.onError} back={props.back} />
  );
}

const LoadingScreen: FC<{ progress: number }> = (props) => {
  return (
    <>
      <ClientHeader />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ padding: "8px 20px 20px", marginTop: 50, maxWidth: 500, display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Progress type="circle" percent={Math.round(props.progress)} />
          <h3 style={{ marginTop: 30, textAlign: "center" }}>Un instant, la certification peut prendre quelques minutes...</h3>
        </div>
      </div>
    </>
  )
}

export default ClientPhoneCode;

