import { Result } from 'antd';

export default function ClientLost() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Result
        title="Il semble que vous soyez perdu."
        subTitle="Re-clicker sur le lien que vous avez reçu."
      />
    </div>
  )
}