import { FC, useEffect, useState } from "react";
import { Button, Rate, Result } from 'antd';
import { analystic_logEvent } from "utils/analystic";
import { setClientRating } from 'utils/database';
import ClientHeader from "Components/UI/Uploader/Header";
import { logout } from "utils/auth";

const ThanksClientPage: FC<{ linkID: string }> = (props) => {

  const [ratingSet, setRatingSet] = useState(false)

  useEffect(() => {
    logout()
    analystic_logEvent("client_onboarding_success_page", { linkID: props.linkID })
  });

  const handleRating = async (value: number) => {
    setRatingSet(true)
    try {
      await setClientRating(props.linkID, value)
    } catch (err) { }
  }

  const onClose = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <>
      <ClientHeader />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ padding: "8px 20px 20px", marginTop: 10, maxWidth: 500, display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <Result
            status="success"
            title="Merci"
            subTitle="Votre document a bien été envoyé et certifié."
          />
          <h3 style={{ marginTop: 0, textAlign: "center" }}>Comment évalueriez vous votre expérience avec Safety Check ?</h3>
          {!ratingSet ? <Rate style={{ maxWidth: 600, fontSize: 40, alignSelf: "center" }} onChange={handleRating} /> :
            <>
              <h4 style={{ textAlign: "center", marginBottom: 15 }}>Merci de nous avoir évalué!</h4>
              <Button type="primary" size={"large"} onClick={onClose}>
                Fermer la fenêtre
              </Button>
            </>
          }
        </div>
      </div>
    </>
  )
}


export default ThanksClientPage;