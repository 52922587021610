import { ref, uploadString } from "firebase/storage";
import { storage } from "firebaseConfig";
import { Uploader } from "types/Uploader.type";


export const uploadSignature = async (uploader: Uploader, clientID: string) => {
    const { signature, linkID, userID } = uploader;
    if ((!userID) || (!signature)) { return }

    const storagePath = `clientsSignatures/${userID}/${clientID}/${linkID}/signature`
    try {
        const storageRef = ref(storage, storagePath);
        await uploadString(storageRef, signature, 'data_url')
        return
    } catch (err) {
        return
    }
}