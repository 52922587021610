import { useState, createContext, FC } from "react";
import { Uploader } from "types/Uploader.type";

export type UploaderState = {
    uploader: Uploader | null,
    setUploader?: (newUploader: Uploader) => void;
}

const contextDefaultValues: UploaderState = {
    uploader: null
}

interface UploaderContextProps {
    uploader: Uploader
}

export const UploaderContext = createContext<UploaderState>(contextDefaultValues)

export const UploaderProvider: FC<UploaderContextProps> = (props) => {

    const [_uploader, _setUploader] = useState<Uploader>(props.uploader)

    const setUploader = (uploader: Uploader): void => {
        _setUploader(uploader)
    }

    return (
        <UploaderContext.Provider
            value={{
                uploader: _uploader,
                setUploader
            }}
        >
            {props.children}
        </UploaderContext.Provider>
    );
};
