import { useEffect, useState, useRef, FC } from "react";
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import styles from "./index.module.css"
import { auth } from "firebaseConfig";
import { Button, Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons"
import ClientHeader from "Components/UI/Uploader/Header";

interface PhoneCodeVerificationProps {
    phoneNumber: string,
    onSuccess: (userID: string) => void
    onError: (message: string) => void
    back: () => void
}

const PhoneCodeVerification: FC<PhoneCodeVerificationProps> = (props) => {

    const INITIAL_COUNTER_VALUE = 30

    const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);
    const element = useRef(null);

    const [counter, setCounter] = useState(INITIAL_COUNTER_VALUE);
    const [loading, setLoading] = useState(false)

    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);

    useEffect(() => {
        if (!recaptcha) {
            const verifier = new RecaptchaVerifier(element.current!, { size: "invisible" }, auth);
            verifier.verify().then(() => setRecaptcha(verifier));
            return
        }
        if (counter == INITIAL_COUNTER_VALUE) { sendCode() }
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);

    }, [counter, recaptcha]);

    const checkCode = async (code: string) => {
        setLoading(true)
        try {
            const result = await confirmationResult!.confirm(code);
            if (!result.user) {
                throw new Error("error")
            }
            const user = result.user;
            setCounter(0)
            props.onSuccess(user.uid)

        } catch (err) {
            props.onError("Le code est incorrect")
            setLoading(false)
        }
    }

    const onFinish = (values: any) => {
        if (values.code.length == 6) { checkCode(values.code) }
        else { props.onError("Le code est incorrect") }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const sendCode = async () => {
        try {
            const confirmation = await signInWithPhoneNumber(auth, props.phoneNumber, recaptcha!)
            setConfirmationResult(confirmation);
        } catch (err) {
            props.onError("Le numéro de téléphone est invalide. Veuillez contacter l'expéditeur.")
        }
    }

    const handleResendClick = () => {
        if (counter == 0) { setCounter(INITIAL_COUNTER_VALUE) }
    }

    return (
        <>
            <ClientHeader />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ padding: "8px 20px 20px", marginTop: 40, maxWidth: 500 }}>
                    <h1
                        style={{
                            fontSize: 17,
                            fontWeight: "400",
                            textAlign: "center",
                            color: "#000",
                        }}
                    >
                        Code à 6 chiffres envoyé via {props.phoneNumber.substring(0, props.phoneNumber.length - 4)} ****
                    </h1>
                    <p
                        style={{
                            marginTop: 5,
                            fontSize: 13,
                            color: "#525c66",
                            textAlign: "center"
                        }}
                    >
                        Consultez votre téléphone pour voir si vous avez un message de notre part. Saisissez le code ci-dessous pour continuer.
                    </p>
                    <div style={{ marginTop: 10 }}>
                        <Form

                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout={"vertical"}
                            style={{ marginTop: 40, width: "100%" }}
                        >
                            <Form.Item

                                label="Saisissez le code que vous avez reçu par SMS"
                                name="code"
                                style={{
                                    marginTop: 30
                                }}
                                rules={[{
                                    required: true,
                                    message: 'Code non valide',
                                }
                                ]}
                            >
                                <Input autoFocus placeholder={"123456"} type="number" prefix={<LockOutlined style={{ color: "#BFBFBF", fontSize: 17 }} />} />
                            </Form.Item>
                            <Form.Item>
                                <Button loading={loading} type="primary" htmlType="submit" style={{ width: "100%", background: "#0666EB" }}>
                                    Valider le code
                                </Button>
                            </Form.Item>
                        </Form>

                        <span className={styles.resendFirst}>
                            Aucun code reçu ?
                            <span
                                onClick={handleResendClick}
                                style={{
                                    color: counter !== 0 ? "#525c66" : "rgb(6, 102, 235)",
                                    marginLeft: 10,
                                    marginTop: 20,
                                    cursor: "pointer"
                                }}
                            >
                                Renvoyer le code {counter !== 0 ? `dans ${counter} secondes` : ""}
                            </span>
                        </span>
                    </div>
                </div>
                <div ref={element}></div>
            </div>
        </>
    )
}

export default PhoneCodeVerification;