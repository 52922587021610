import "./App.css"
import { Routes, Route, BrowserRouter } from "react-router-dom";
import UploaderController from "./Screens/Uploader/Controller";
import ClientLost from "./Screens/Errors/ClientLost";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/o/:linkID" element={<UploaderController />} />
        <Route path="/o/:linkID/:teamName" element={<UploaderController />} />
        <Route path="*" element={<ClientLost />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;