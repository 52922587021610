import { Button } from 'antd';
import ClientHeader from 'Components/UI/Uploader/Header';
import ClientNextButton from 'Components/UI/Uploader/NextButton';
import { UploaderContext } from 'Providers/UploaderProvider';
import SignaturePad from 'react-signature-canvas'
import { FC, useContext, useRef, useState } from "react";
import { getPrettyDate } from 'utils/dateHelper';

const ClientSignPage: FC<{ next: () => void, back: () => void }> = (props) => {

    const { uploader, setUploader } = useContext(UploaderContext)

    const sigPad = useRef() as React.MutableRefObject<any>;
    const [isSign, setIsSign] = useState(false)

    const clear = () => {
        sigPad.current.clear()
        setIsSign(false)
    }

    const handleNext = () => {
        const imageData = sigPad.current.toDataURL("image/png") as string
        setUploader!({ ...uploader!, signature: imageData })
        props.next()
    }

    return (
        <>
            <ClientHeader />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ padding: "8px 20px 20px", marginTop: 30, maxWidth: 500, justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h1
                        style={{
                            fontSize: 17,
                            fontWeight: "400",
                            color: "#000",
                            textAlign: "center"
                        }}
                    >
                        Signez (utilisez votre doigt ou votre souris)
                    </h1>
                    <p
                        style={{
                            marginTop: 5,
                            fontSize: 13,
                            color: "#525c66",
                            textAlign: "center"
                        }}
                    >
                        Afin de valider votre RIB, merci de dessiner votre signature dans l'espace ci-dessous.
                    </p>
                    <div style={{ border: "1px dashed #828B94", borderRadius: 19, display: "flex",
                    alignItems: "center", flexDirection: "column", paddingBottom: 10,
                    width: 290
                    }}>
                        {/*   <CloseCircleFilled onClick={clear} style={{ alignSelf: "flex-end", marginTop: 5, marginRight: 5, color: "#828B94" }} /> */}
                        <SignaturePad
                            onBegin={() => setIsSign(true)}
                            backgroundColor={"transparent"}
                            canvasProps={{ width: 290, height: 160 }}
                            ref={sigPad} />
                        <div style={{ height: 2, background: "#D4D2D5", width: "80%", marginTop: 2 }} />
                        <span>{getPrettyDate(new Date())}</span>
                        <Button onClick={clear} style={{ marginTop: 5, fontSize: 10 }}>Effacer la signature</Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                        <ClientNextButton onpress={handleNext} title={"Suivant"} isActive={isSign} />
                    </div>
                </div>
            </div>
        </>

    )
}

export default ClientSignPage;
