import { httpsCallable } from "firebase/functions"
import { cloudFunction } from "firebaseConfig"
import { UploaderLink } from "types/Uploader.type"

export const getClientLink = async (linkID: string): Promise<UploaderLink> => {
    try {
        const getLink = httpsCallable(cloudFunction, "getClientLinkInfo");
        const link = await getLink({ linkID })
        return link.data as UploaderLink
    } catch (err) {
        throw err
    }
}